import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "./Components/Header/Header";
import axios from "axios";
// import "./App.css";
import "./assets/styles/CreateEvent.css";
import { QRCodeCanvas } from "qrcode.react";

import { API_CREATE_EVENT_URL, API_GET_EVENT_LIST } from "./endpoints";

const SECRET_KEY = "Catalina83"; // Define your fixed secret key

const CreateEvent = () => {
  const [eventId, setEventId] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [coverImageFile, setCoverImageFile] = useState(null); // For file upload
  const [customMessage, setCustomMessage] = useState("");
  const [albumOf, setAlbumOf] = useState("");
  const [albumCreatedBy, setAlbumCreatedBy] = useState(""); // New state for Albumcreatedby

  const [secret, setSecret] = useState(""); // State for secret input
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [successMessageFileUpload, setSuccessMessageFileUpload] = useState("");
  const [errorMessageFileUpload, setErrorMessageFileUpload] = useState("");

  const [eventUrl, setEventUrl] = useState(""); // State to store the event URL
  const [eventDate, setEventDate] = useState(""); // State to store the event URL

  const qrCodeRef = useRef(null); // Ref for the QR code

  const [hasVideo, setHasVideo] = useState(false); // New state for hasVideo
  const [hasGuestBook, setHasGuestBook] = useState(false); // New state for hasVideo
  const [isSecondaryTheme, setIsSecondaryTheme] = useState(false); // New state for theme
  const [uploadedImageUrl, setUploadedImageUrl] = useState(""); // To store the uploaded image URL

  const [qrCodeFile, setQrCodeFile] = useState(null);
  const [uploadedQRCodeUrl, setUploadedQRCodeUrl] = useState(""); // Added state for uploaded QR code URL

  // const downloadQRCode = () => {
  //   const qrCanvas = document.querySelector('canvas'); // Select the QR code canvas element

  //   if (qrCanvas) {
  //     const image = qrCanvas.toDataURL('image/png'); // Convert canvas to PNG
  //     const link = document.createElement('a'); // Create an anchor element
  //     link.href = image; // Set the image as the href
  //     link.download = 'qr-code.png'; // Set the download attribute
  //     link.click(); // Programmatically click the link to trigger the download
  //   } else {
  //     console.error('QR code canvas not found!');
  //   }
  // };
  const hiddenCanvasRef = useRef(null); // For the hidden QR code canvas

  const downloadQRCode = () => {
    const qrCanvas = hiddenCanvasRef.current.querySelector("canvas");
    if (qrCanvas) {
      const url = qrCanvas.toDataURL("image/png");
      const eventId = eventUrl.split("eventId=")[1] || "QR_Code";
      const link = document.createElement("a");
      link.href = url;
      link.download = `${eventId}.png`;
      link.click();
    } else {
      console.error("QR code canvas not found!");
    }
  };

  const handleQRCodeUpload = async (event) => {
    event.preventDefault();
    setErrorMessageFileUpload("");
    setSuccessMessageFileUpload("");

    if (!qrCodeFile) {
      setErrorMessageFileUpload("Por favor, selecciona un archivo para subir.");
      return;
    }

    try {
      const response = await axios.post(
        "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/add-qr-code-to-storage",
        {
          filename: qrCodeFile.name,
          contentType: qrCodeFile.type,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to get signed URL");
      }

      const { uploadUrl, publicUrl } = response.data;

      console.log("Received upload URL:", uploadUrl);
      console.log("Public URL:", publicUrl);

      const uploadResponse = await axios.put(uploadUrl, qrCodeFile, {
        headers: {
          "Content-Type": qrCodeFile.type,
        },
      });

      if (uploadResponse.status === 200) {
        console.log("QR Code uploaded successfully!");

        setUploadedQRCodeUrl(publicUrl); // Set the uploaded QR code URL here
        setSuccessMessageFileUpload("El código QR se subió correctamente.");
        setQrCodeFile(null);
      } else {
        throw new Error("Failed to upload QR code to Cloud Storage");
      }
    } catch (error) {
      console.error("Error uploading QR code:", error);
      setErrorMessageFileUpload("Error al subir el código QR.");
    }
  };

  const handleCreateEvent = async (event) => {
    event.preventDefault();
    setIsCreating(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Check if the entered secret is correct
    if (secret !== SECRET_KEY) {
      setErrorMessage("Invalid secret key.");
      setIsCreating(false);
      return;
    }
    // Function to download the QR code as an image

    try {
      // Create JSON payload
      const payload = {
        eventId,
        coverImageUrl,
        customMessage,
        albumOf,
        albumCreatedBy,
        eventDate,
        hasVideo,
        hasGuestBook,
        isSecondaryTheme,
      };

      // Handle file upload separately if needed
      if (coverImageFile) {
        // Upload file to a storage service (e.g., Firebase Storage) and get the URL
        // Example placeholder for file upload logic
        // const fileUrl = await uploadFile(coverImageFile);
        // payload.coverImageUrl = fileUrl; // Update payload with file URL
      }

      // Send JSON payload
      const response = await axios.post(API_CREATE_EVENT_URL, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        setSuccessMessage("¡El evento se ha creado con éxito!");

        // Generate and set the event URL
        const generatedUrl = `https://snap-moments.com/album?eventId=${eventId}`;
        setEventUrl(generatedUrl);

        // Clear inputs
        setEventId("");
        setCoverImageUrl("");
        setCoverImageFile(null);
        setCustomMessage("");
        setAlbumOf("");
        setAlbumCreatedBy("");
        setEventDate("");
        setSecret(""); // Clear secret input
        setHasVideo(false);
        setHasGuestBook(false);
      } else {
        setErrorMessage("Hubo un error al crear el evento.");
      }
    } catch (error) {
      console.error("Error creating event:", error);
      setErrorMessage(`${error.response.data.error}`);
      // setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsCreating(false);
    }
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    setErrorMessageFileUpload("");
    setSuccessMessageFileUpload("");

    if (!coverImageFile) {
      setErrorMessageFileUpload("Por favor, selecciona un archivo para subir.");
      return;
    }

    try {
      // Step 1: Get the signed URL from the Cloud Function
      const response = await axios.post(
        "https://us-central1-moonlit-sphinx-400613.cloudfunctions.net/add-cover-photo-album",
        {
          filename: coverImageFile.name,
          contentType: coverImageFile.type,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to get signed URL");
      }

      const { uploadUrl, publicUrl } = response.data;

      // Log the upload URL and public URL for debugging
      console.log("Received upload URL:", uploadUrl);
      console.log("Public URL:", publicUrl);

      // Step 2: Upload the file using the signed URL
      const uploadResponse = await axios.put(uploadUrl, coverImageFile, {
        headers: {
          "Content-Type": coverImageFile.type, // Make sure the content type matches the file type
        },
      });

      if (uploadResponse.status === 200) {
        console.log("File uploaded successfully!");

        // Step 3: Update the UI with the public URL
        setUploadedImageUrl(publicUrl);
        setSuccessMessageFileUpload("La imagen se subió correctamente.");
        setCoverImageFile(null);
      } else {
        throw new Error("Failed to upload image to Cloud Storage");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessageFileUpload("Error al subir la imagen.");
    }
  };

  return (
    <div className="create-event-container">
      <Link to="/">
        <Header />
      </Link>
      <h2>Crear Nuevo Evento</h2>

      {/* Separate Form for File Upload */}
      {/* <h2>Subir Imagen de Portada</h2> */}
      <form onSubmit={handleFileUpload}>
        <label>Seleccionar archivo:</label>
        <input
          type="file"
          onChange={(e) => setCoverImageFile(e.target.files[0])}
          required
          className="uploadFile"
        />
        <button type="submit">Subir Imagen</button>
        {successMessageFileUpload && (
          <p className="success-message">{successMessageFileUpload}</p>
        )}

        {/* Display error message if exists */}
        {errorMessageFileUpload && (
          <p className="error-message">{errorMessageFileUpload}</p>
        )}

        {/* Display the uploaded image URL for the user to copy */}
        {uploadedImageUrl && (
          <div>
            <p>La URL de la imagen cargada:</p>
            <input
              type="text"
              value={uploadedImageUrl}
              readOnly
              className="uploadFile"
              style={{ marginTop: "10px" }}
            />
            <button
              onClick={() => navigator.clipboard.writeText(uploadedImageUrl)}
              style={{ marginTop: "10px" }}
            >
              Copiar URL
            </button>
          </div>
        )}
      </form>

      <hr />

      <form onSubmit={handleCreateEvent}>
        <label>Ingresá el ID del evento:</label>
        <input
          type="text"
          value={eventId}
          onChange={(e) => setEventId(e.target.value)}
          placeholder="ID del evento"
          required
        />
        {/* <label>Mensaje personalizado:</label>
        <input
          type="text"
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          placeholder="Mensaje personalizado"
        /> */}
        <label>Álbum de:</label>
        <input
          type="text"
          value={albumOf}
          onChange={(e) => setAlbumOf(e.target.value)}
          placeholder="Nombre del álbum"
          required
        />
        <label>Fecha del Evento:</label>
        <input
          type="date"
          value={eventDate}
          onChange={(e) => setEventDate(e.target.value)}
          placeholder="Fecha del Evento:"
          required
        />
        <label>Álbum creado por:</label> {/* New input field */}
        <input
          type="text"
          value={albumCreatedBy}
          onChange={(e) => setAlbumCreatedBy(e.target.value)}
          placeholder="Nombre del creador del álbum"
          required
        />
        {/* 
        <label>Subir una imagen de portada (opcional):</label>
        <input
          type="file"
          onChange={(e) => setCoverImageFile(e.target.files[0])}
        /> 
        */}
        <label>URL para imagen de portada:</label>
        <input
          type="text"
          value={coverImageUrl}
          onChange={(e) => setCoverImageUrl(e.target.value)}
          placeholder="URL de la imagen de portada"
          required
        />
        {/* <label>¿El evento tiene video?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value={true}
              checked={hasVideo === true}
              onChange={() => setHasVideo(true)}
            />
            Sí
          </label>
          <label>
            <input
              type="radio"
              value={false}
              checked={hasVideo === false}
              onChange={() => setHasVideo(false)}
            />
            No
          </label>
        </div> */}
        <label>¿El evento tiene Guestbook?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value={true}
              checked={hasGuestBook === true}
              onChange={() => setHasGuestBook(true)}
            />
            Sí
          </label>

          <label>
            <input
              type="radio"
              value={false}
              checked={hasGuestBook === false}
              onChange={() => setHasGuestBook(false)}
            />
            No
          </label>
        </div>
        <label>¿Aplicar Tema Secundario?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value={true}
              checked={isSecondaryTheme === true}
              onChange={() => setIsSecondaryTheme(true)}
            />
            Sí
          </label>
          <label>
            <input
              type="radio"
              value={false}
              checked={isSecondaryTheme === false}
              onChange={() => setIsSecondaryTheme(false)}
            />
            No
          </label>
        </div>
        <label>Token de autorización:</label>
        <input
          type="text"
          value={secret}
          onChange={(e) => setSecret(e.target.value)}
          placeholder="Secret Key"
          required
        />
        <button type="submit" disabled={isCreating}>
          {isCreating ? "Creando..." : "Crear Evento"}
        </button>
        {isCreating && <p>Creando el evento, por favor espera...</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}



        {/* Display the event URL after creation */}
        {eventUrl && (
          <div className="event-url">
            <p>
              El evento ha sido creado exitosamente. Accedé al álbum en el
              siguiente enlace:
            </p>
            <a href={eventUrl} target="_blank" rel="noopener noreferrer">
              {eventUrl}
            </a>



            {/* Generate and display the QR code for the event URL */}
            <div className="qr-code">
              <h2>QR Code para el evento:</h2>
              <QRCodeCanvas value={eventUrl} size={150} />
              <i
                className="fas fa-download download-qr-icon"
                onClick={downloadQRCode}
              />
              <div style={{ display: "none" }} ref={hiddenCanvasRef}>
                <QRCodeCanvas value={eventUrl} size={1000} />
              </div>
            </div>


            <div>
              <label>Subir código QR:</label>
              <input
                type="file"
                onChange={(e) => setQrCodeFile(e.target.files[0])}
                required
              />
              <button type="button" onClick={handleQRCodeUpload}>
                Subir Código QR
              </button>

              {successMessageFileUpload && (
                <p className="success-message">{successMessageFileUpload}</p>
              )}

              {errorMessageFileUpload && (
                <p className="error-message">{errorMessageFileUpload}</p>
              )}

              {uploadedQRCodeUrl && (
                <div style={{ marginTop: "10px" }}>
                  <p>La URL del código QR cargado:</p>
                  <input
                    type="text"
                    value={uploadedQRCodeUrl}
                    readOnly
                    className="uploadFile"
                    style={{ marginTop: "10px" }}
                  />
                  <button
                     onClick={(e) => {
                      e.preventDefault(); // Prevent form submission
                      navigator.clipboard.writeText(uploadedQRCodeUrl);
                    }}
                    style={{ marginTop: "10px" }}
                  >
                    Copiar URL
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </form>

      <footer className="support-footer">
        <p>
          Si experimentás algún inconveniente durante la creación del evento,
          escribinos a{" "}
          <a href="mailto:contacto@snap-moments.com">
            contacto@snap-moments.com
          </a>
          .
        </p>
      </footer>
    </div>
  );
};

export default CreateEvent;
